import { HttpErrorResponse, HttpParams } from "@angular/common/http";

import { ToastrService } from "ngx-toastr";

export function formatFriendlyLink(str: string) {
    // Verifies if the String has accents and replace them
    if (str.search(/[\xC0-\xFF]/g) > -1) {
      str = str
        .replace(/[\xC0-\xC5]/g, "A")
        .replace(/[\xC6]/g, "AE")
        .replace(/[\xC7]/g, "C")
        .replace(/[\xC8-\xCB]/g, "E")
        .replace(/[\xCC-\xCF]/g, "I")
        .replace(/[\xD0]/g, "D")
        .replace(/[\xD1]/g, "N")
        .replace(/[\xD2-\xD6\xD8]/g, "O")
        .replace(/[\xD9-\xDC]/g, "U")
        .replace(/[\xDD]/g, "Y")
        .replace(/[\xDE]/g, "P")
        .replace(/[\xE0-\xE5]/g, "a")
        .replace(/[\xE6]/g, "ae")
        .replace(/[\xE7]/g, "c")
        .replace(/[\xE8-\xEB]/g, "e")
        .replace(/[\xEC-\xEF]/g, "i")
        .replace(/[\xF1]/g, "n")
        .replace(/[\xF2-\xF6\xF8]/g, "o")
        .replace(/[\xF9-\xFC]/g, "u")
        .replace(/[\xFE]/g, "p")
        .replace(/[\xFD\xFF]/g, "y");
    }

    return str.replace(/[^A-Z0-9]+/ig, "-");
  }


  export function validateEmail(email) {
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regularExpression.test(String(email).toLowerCase());
   }



   export function getParamsFromJson(_params?): HttpParams {
    let params = new HttpParams();
		Object.keys(_params).map((key, index) => {
            if(_params[key] != undefined && _params[key] != '')
			    params = params.append(key, _params[key])
		})
    return params;
  }

  export function toogleLeftRight(list: any[]) {
    if (list) {
      var left = true;
      list.forEach(element => {

        if(element.rtlOrientation) {
          element['__rtl'] = true;
        } else {
          element['__rtl'] = left ? false : true;
          left = !left;
        }

      });
    }
  }



  export function showApiMessages(err: HttpErrorResponse, toastr: ToastrService, error: boolean = false) {
    let errorMessage = '';
    if (err.status === 400 || err.status === 422) {

      const retError = err.error;

      if (retError.errors) {
        for(var propertyName in retError.errors) {

          if(!retError.errors[propertyName]){
            this.toastr.error('Solicitação rejeitada..');
          } else {
            retError.errors[propertyName].forEach(element => {
              if(error)
               this.toastr.error(element);
              else
               this.toastr.info(element);
            });
          }
        }

      } else {
        errorMessage += 'Solicitação rejeitada..', 'error';
      }

      if (errorMessage !== '') {
        //this.sharedInfo.onNotifierError.emit({type: "warn", msg: errorMessage});
        //this.toastr.error(errorMessage);
      }

    }
  }
