import { ApiService } from "src/app/shared/services/api";
import { GalleryEntity } from "../models/galleryEntity";
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseRecords } from "../models/responseRecords";
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class GalleryService {

  public photoBasePath = `${environment.urls.filesUrl}`;

  public service: ApiService<GalleryEntity, ResponseRecords<GalleryEntity[]>>;

  constructor(private http: HttpClient) {
    this.service = new ApiService(http, `${environment.urls.bffUrl}/gallery`);
  }

}
