<div>


  <img #image id="image" (load)="onImageLoaded()" alt="{{alt}}" src="{{source}}" [style.height.px]="height">


  <ng-container *ngIf="!imageLoaded">
    <div *ngIf="showLoading" class="loader">
      <div class="skeleton-loader" style="height: 100% !important; width: 100% !important; padding: 0 !important;"></div>
    </div>
    <div *ngIf="!showLoading" style="background-color: rgb(255, 255, 255);">

    </div>
  </ng-container>

</div>
