
export class GalleryEntity {
    id: string;
    name: string;
    description: string;
    photos: GalleryFileEntity[];
}


export class GalleryFileEntity {
    id: string;
    name: string;
    galleryID: string;
    caption: string;
    vLibra: string;
    details: string;
    urlNavigate: string;
    order: number;
    fileName: string;
    path: string;
    srcImage: any;
    target: string;
}
