import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'groupBy' })

export class GroupByPipe implements PipeTransform {

    constructor() { }

    transform(data: any[], args: any): gData[] {

        var ret: gData[] = [];

        if (data && args) {

            var groupName = args;

            data.forEach(element => {

                var g = ret.find(i => i.name == element[groupName]);

                if (g == null) {

                    g = new gData();

                    g.name = element[groupName];

                    g.itens = [];

                    g.itens.push(element);

                    ret.push(g);

                }

                else {

                    if (g.itens == null) {

                        g.itens = [];

                    }

                    g.itens.push(element);

                }

            });

            return ret;

        }

        return ret;

    }

}

export class gData {

  name: any;

  itens: any[] = [];

}
