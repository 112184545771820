

import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'periodFormat'
})
export class PeriodFormatPipe implements PipeTransform {
  transform(value: any): any {

    var initialDate = value?.initialDate ? moment(value.initialDate).format('DD/MM/YY') : 'd/mm/aa';
    var finalDate = value?.finalDate ? moment(value.finalDate).format('DD/MM/YY') : 'd/mm/aa';

    var resp = `${initialDate} - ${finalDate}`;
    return resp ;

  }
}
