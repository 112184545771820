import { ComponentRef, Directive, ElementRef, Injector, Input, NgModuleRef, Type, ViewContainerRef, createNgModuleRef } from "@angular/core";
import { PageComponentEntity, PageModel } from "../models/dynamicpage";

import { CarouselComponent } from "src/app/layout/@components/carousel/carousel/carousel.component";
import { CircleImagesCardsComponent } from "src/app/layout/@components/highlightData/circleImages-cards/circleImages-cards.component";
import { EventPreviewComponent } from "src/app/layout/@components/event/event-preview/event-preview.component";
import { FaqPreviewComponent } from "src/app/layout/@components/faq/faq-preview/faq-preview.component";
import { HighlightDataCardsComponent } from "src/app/layout/@components/highlightData/highlightData-cards/highlightData-cards.component";
import { HighlightDataPreviewComponent } from "src/app/layout/@components/highlightData/highlightData-preview/highlightData-preview.component";
import { HighlightDataServicesComponent } from "src/app/layout/@components/highlightData/highlightData-services/highlightData-services.component";
import { HtmlIframeViewComponent } from "src/app/layout/@components/html-iframe/html-iframe/html-iframe.component";
import { NewsletterPreviewComponent } from "src/app/layout/@components/newsletter/newsletter-preview/newsletter-preview.component";
import { PageTitleComponent } from "src/app/layout/@components/page-title/page-title/page-title.component";
import { ReservationContactComponent } from "src/app/layout/@components/contacts/reservation-contact/reservation-contact/reservation-contact.component";
import { StartupContactComponent } from "src/app/layout/@components/contacts/startup-contact/startup-contact/startup-contact.component";
import { StartupGroupPreviewComponent } from "src/app/layout/@components/startup/startup-group/startup-group-preview/startup-group-preview.component";
import { StartupPageTitleComponent } from "src/app/layout/@components/startup/startup/startup-page-title/startup-page-title/startup-page-title.component";
import { StartupPreviewComponent } from "src/app/layout/@components/startup/startup/startup-preview/startup-preview/startup-preview.component";
import { TariffsFilterComponent } from "src/app/layout/@components/tariffs-filter/tariffs-filter.component";
import { TestimonialPreviewComponent } from "src/app/layout/@components/testimonial/testimonial-preview/testimonial-preview.component";
import { TripAdvisorPreviewComponent } from "src/app/layout/@components/trip-advisor/trip-advisor-preview/trip-advisor-preview.component";
import { UnitFacadePhotoComponent } from "src/app/layout/@components/unit-facade-photo/unit-facade-photo/unit-facade-photo.component";

@Directive({
  selector: '[appDynamicComponent]'
})
export class DynamicComponentDirective {

  @Input('page') page: PageModel;
  @Input('pageComponent') pageComponent: PageComponentEntity;

  cmpref: any;


  constructor(private element: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private _injector: Injector
  ) { }

  ngAfterViewInit() {

    var config = JSON.parse(this.pageComponent.jsonConfig || '{}');

    switch (this.pageComponent.component.key) {

      case 'pageTitle': {
        import('../../layout/@components/page-title/page-title.shared.module').then((importedFile) => {
          var componentToOpen = importedFile.PageTitleSharedComponentsModule.components.dynamicComponent;
          var pageTitleCmpref = this._openLazyLoadedComponent(importedFile, componentToOpen) as ComponentRef<PageTitleComponent>;
          pageTitleCmpref.instance.page = this.page
          pageTitleCmpref.instance.style = config.style;
          pageTitleCmpref.instance.compInfo = config.compInfo;
          pageTitleCmpref.changeDetectorRef.detectChanges();
          this.cmpref = pageTitleCmpref;
        });
        break;
      }

      case 'unitImage': {
        import('../../layout/@components/unit-facade-photo/unit-facade-photo.shared.module').then((importedFile) => {
          var componentToOpen = importedFile.UnitFacadePhotoSharedComponentsModule.components.dynamicComponent;
          var cmpref = this._openLazyLoadedComponent(importedFile, componentToOpen) as ComponentRef<UnitFacadePhotoComponent>;
          cmpref.instance.config = config;
          cmpref.changeDetectorRef.detectChanges();
          this.cmpref = cmpref;
        });
        break;
      }

      case 'tariffsFilter': {
        import('../../layout/@components/tariffs-filter/tariffs-filter.shared.module').then((importedFile) => {
          var componentToOpen = importedFile.TariffsFilterSharedComponentsModule.components.dynamicComponent;
          var cmpref = this._openLazyLoadedComponent(importedFile, componentToOpen) as ComponentRef<TariffsFilterComponent>;
          cmpref.instance.filterMode = 'home';
          cmpref.changeDetectorRef.detectChanges();
          this.cmpref = cmpref;
        });
        break;
      }

      case 'googleMapsAddress': {
        import('../../layout/@components/map-google/map-google.shared.module').then((importedFile) => {
          var componentToOpen = importedFile.MapGoogleSharedComponentsModule.components.dynamicComponent;
          var cmpref = this._openLazyLoadedComponent(importedFile, componentToOpen);
          this.cmpref = cmpref;
        });
        break;
      }

      case 'instagramWidget': {
        import('../../layout/@components/instagram-widget/instagram-widget.shared.module').then((importedFile) => {
          var componentToOpen = importedFile.InstagramWidgetSharedComponentsModule.components.dynamicComponent;
          var cmpref = this._openLazyLoadedComponent(importedFile, componentToOpen);
          this.cmpref = cmpref;
        });
        break;
      }

      case 'carousel': {
        import('../../layout/@components/carousel/carousel/carousel.shared.module').then((importedFile) => {
          var componentToOpen = importedFile.CarouselSharedComponentsModule.components.dynamicComponent;
          var cmpref = this._openLazyLoadedComponent(importedFile, componentToOpen) as ComponentRef<CarouselComponent>;
          cmpref.instance.onLoadedData.subscribe(resp => {
            if ((resp && resp.length <= 0)) {
              this.pageComponent.height = 0;
              this.pageComponent.marginTop = 0;
              this.pageComponent.marginBottom = 0;
            }
          })
          //cmpref.instance.filterMode = 'home';
          cmpref.changeDetectorRef.detectChanges();
          this.cmpref = cmpref;
        });
        break;
      }


      case 'alternateImageGallery': {
        import('../../layout/@components/highlightData/highlightData.shared.module').then((importedFile) => {
          var componentToOpen = importedFile.HighlightDataSharedComponentsModule.components.dynamicComponent;
          var cmpref = this._openLazyLoadedComponent(importedFile, componentToOpen) as ComponentRef<HighlightDataPreviewComponent>;
          cmpref.instance.siteKey = config.siteKeyId;

          if (config?.style == 'expanded') {
            cmpref.instance.galleryClass = 'galleryDetails';
            cmpref.instance.imagePercent = 50;
            cmpref.instance.textPercent = config.textPercent ? config.textPercent : 40;
          } else {

          }

          cmpref.changeDetectorRef.detectChanges();
          this.cmpref = cmpref;
        });
        break;
      }

      case 'horizontalCards': {
        import('../../layout/@components/highlightData/highlightData.shared.module').then((importedFile) => {
          var componentToOpen = importedFile.HighlightDataSharedComponentsModule.components.highlightDataCards;
          var cmpref = this._openLazyLoadedComponent(importedFile, componentToOpen) as ComponentRef<HighlightDataCardsComponent>;
          cmpref.instance.siteKey = config.siteKeyId;
          cmpref.instance.showTitleInfo = config.showTitleInfo;
          cmpref.instance.numberOfCardsInColumn = (config?.numberOfCardsInColumn ? config.numberOfCardsInColumn : 4);
          cmpref.changeDetectorRef.detectChanges();
          this.cmpref = cmpref;
        });
        break;
      }

      case 'circleImagesCards': {
        import('../../layout/@components/highlightData/highlightData.shared.module').then((importedFile) => {
          var componentToOpen = importedFile.HighlightDataSharedComponentsModule.components.circleImagesCards;
          var cmpref = this._openLazyLoadedComponent(importedFile, componentToOpen) as ComponentRef<CircleImagesCardsComponent>;
          cmpref.instance.siteKey = config.siteKeyId;
          cmpref.instance.showTitleInfo = config.showTitleInfo;
          cmpref.instance.imageBorderRadius = config.imageBorderRadius;
          cmpref.instance.imageSize = config.imageSize;
          cmpref.instance.numberOfCardsInColumn = (config?.numberOfCardsInColumn ? config.numberOfCardsInColumn : 3);
          cmpref.instance.showBorder = config.showBorder;
          cmpref.changeDetectorRef.detectChanges();
          this.cmpref = cmpref;
        });
        break;
      }

      case 'serviceIcons': {
        import('../../layout/@components/highlightData/highlightData.shared.module').then((importedFile) => {
          var componentToOpen = importedFile.HighlightDataSharedComponentsModule.components.highlightDataServices;
          var cmpref = this._openLazyLoadedComponent(importedFile, componentToOpen) as ComponentRef<HighlightDataServicesComponent>;
          cmpref.instance.config = config;
          cmpref.instance.siteKey = config.siteKeyId;
          cmpref.changeDetectorRef.detectChanges();
          this.cmpref = cmpref;
        });
        break;
      }

      case 'tripadvisor': {
        import('../../layout/@components/trip-advisor/trip-advisor.shared.module').then((importedFile) => {
          var componentToOpen = importedFile.TripAdvisorSharedComponentsModule.components.dynamicComponent;
          var cmpref = this._openLazyLoadedComponent(importedFile, componentToOpen) as ComponentRef<TripAdvisorPreviewComponent>;
          cmpref.instance.config = config;
          cmpref.changeDetectorRef.detectChanges();
          this.cmpref = cmpref;
        });
        break;
      }

      case 'testimonials': {
        import('../../layout/@components/testimonial/testimonial.shared.module').then((importedFile) => {
          var componentToOpen = importedFile.TestimonialSharedComponentsModule.components.dynamicComponent;
          var cmpref = this._openLazyLoadedComponent(importedFile, componentToOpen) as ComponentRef<TestimonialPreviewComponent>;
          cmpref.instance.filters = { limit: 8 };
          cmpref.instance.showMore = true;
          cmpref.instance.config = config;
          cmpref.changeDetectorRef.detectChanges();
          this.cmpref = cmpref;
        });
        break;
      }

      case 'eventPreview': {
        import('../../layout/@components/event/event.shared.module').then((importedFile) => {
          var componentToOpen = importedFile.EventSharedComponentsModule.components.dynamicComponent;
          var cmpref = this._openLazyLoadedComponent(importedFile, componentToOpen) as ComponentRef<EventPreviewComponent>;
          cmpref.instance.filters = { limit: 4, onlyValid: true, type: 1 };
          cmpref.instance.showMore = true;
          cmpref.instance.showImageInCards = config.showImageInCards;
          cmpref.instance.numberOfCardsInColumn = config.numberOfCardsInColumn;
          cmpref.instance.imageObjectFit = config.imageObjectFit;
          cmpref.instance.onLoadedData.subscribe(resp => {
            if ((resp && resp.length <= 0)) {
              this.pageComponent.height = 0;
              this.pageComponent.marginTop = 0;
              this.pageComponent.marginBottom = 0;
            }
          })
          cmpref.changeDetectorRef.detectChanges();
          this.cmpref = cmpref;
        });
        break;
      }

      case 'newsPreview': {
        import('../../layout/@components/newsletter/newsletter.shared.module').then((importedFile) => {
          var componentToOpen = importedFile.NewsletterSharedComponentsModule.components.dynamicComponent;
          var cmpref = this._openLazyLoadedComponent(importedFile, componentToOpen) as ComponentRef<NewsletterPreviewComponent>;
          cmpref.instance.filters = { limit: 3 };
          cmpref.instance.showMore = true;
          cmpref.instance.showImageInCards = config.showImageInCards;
          cmpref.instance.numberOfCardsInColumn = config.numberOfCardsInColumn;
          cmpref.instance.imageObjectFit = config.imageObjectFit;
          cmpref.instance.onLoadedData.subscribe(resp => {
            if ((resp && resp.length <= 0)) {
              this.pageComponent.height = 0;
              this.pageComponent.marginTop = 0;
              this.pageComponent.marginBottom = 0;
            }
          })
          cmpref.changeDetectorRef.detectChanges();
          this.cmpref = cmpref;
        });
        break;
      }

      case 'startupGroupCards': {
        import('../../layout/@components/startup/startup-group/startup-group.shared.module').then((importedFile) => {
          var componentToOpen = importedFile.StartupGroupSharedComponentsModule.components.dynamicComponent;
          var cmpref = this._openLazyLoadedComponent(importedFile, componentToOpen) as ComponentRef<StartupGroupPreviewComponent>;
          cmpref.instance.numberOfCardsInColumn = config.numberOfCardsInColumn || 2;
          cmpref.instance.imageObjectFit = config?.imageObjectFit || 'cover';
          cmpref.changeDetectorRef.detectChanges();
          this.cmpref = cmpref;
        });
        break;
      }

      case 'startupsPageTitle': {
        import('../../layout/@components/startup/startup/startup-page-title/startup-page-title.shared.module').then((importedFile) => {
          var componentToOpen = importedFile.StartupPageTitleSharedComponentsModule.components.dynamicComponent;
          var cmpref = this._openLazyLoadedComponent(importedFile, componentToOpen) as ComponentRef<StartupPageTitleComponent>;
          cmpref.instance.page = this.page;
          cmpref.changeDetectorRef.detectChanges();
          this.cmpref = cmpref;
        });
        break;
      }

      case 'startupCards': {
        import('../../layout/@components/startup/startup/startup-preview/startup-preview.shared.module').then((importedFile) => {
          var componentToOpen = importedFile.StartupSharedComponentsModule.components.dynamicComponent;
          var cmpref = this._openLazyLoadedComponent(importedFile, componentToOpen) as ComponentRef<StartupPreviewComponent>;
          cmpref.instance.config = config;
          cmpref.instance.showTitle = config.showTitle || true;
          cmpref.instance.numberOfCardsInColumn = config.numberOfCardsInColumn || 2;
          cmpref.instance.imageObjectFit = config.imageObjectFit || 'cover';
          cmpref.changeDetectorRef.detectChanges();
          this.cmpref = cmpref;
        });
        break;
      }


      case 'commonQuestions': {
        import('../../layout/@components/faq/faq.shared.module').then((importedFile) => {
          var componentToOpen = importedFile.FaqSharedComponentsModule.components.dynamicComponent;
          var cmpref = this._openLazyLoadedComponent(importedFile, componentToOpen) as ComponentRef<FaqPreviewComponent>;
          cmpref.instance.config = config;
          cmpref.instance.showFilters = false;
          cmpref.instance.showTitle = true;
          cmpref.changeDetectorRef.detectChanges();
          this.cmpref = cmpref;
        });
        break;
      }

      case 'reservationContactForm': {
        import('../../layout/@components/contacts/reservation-contact/reservation-contact.shared.module').then((importedFile) => {
          var componentToOpen = importedFile.ReservationContactSharedComponentsModule.components.dynamicComponent;
          var cmpref = this._openLazyLoadedComponent(importedFile, componentToOpen) as ComponentRef<ReservationContactComponent>;
          cmpref.instance.siteKey = config.siteKeyId;
          cmpref.instance.messageOk = (config?.messageOk) || cmpref.instance.messageOk;
          cmpref.changeDetectorRef.detectChanges();
          this.cmpref = cmpref;
        });
        break;
      }

      case 'contactForm': {
        import('../../layout/@components/contacts/startup-contact/startup-contact.shared.module').then((importedFile) => {
          var componentToOpen = importedFile.StartupContactSharedComponentsModule.components.dynamicComponent;
          var cmpref = this._openLazyLoadedComponent(importedFile, componentToOpen) as ComponentRef<StartupContactComponent>;
          cmpref.instance.config = config;
          cmpref.changeDetectorRef.detectChanges();
          this.cmpref = cmpref;
        });
        break;
      }

      case 'dynamicHtmlPage': {
        import('../../layout/@components/html-iframe/html-iframe.shared.module').then((importedFile) => {
          var componentToOpen = importedFile.HtmlIframeViewComponentsModule.components.dynamicComponent;
          var cmpref = this._openLazyLoadedComponent(importedFile, componentToOpen) as ComponentRef<HtmlIframeViewComponent>;
          cmpref.instance.inputKey = config?.dynamicPageKey;
          cmpref.changeDetectorRef.detectChanges();
          this.cmpref = cmpref;
        });
        break;
      }


      default: {
        console.info('dont component key')
        break;
      }

    }


  }

  private _openLazyLoadedComponent<T>(importedFile: T, componentToOpen
  ) {
    //const module: Type<T> = (<any>importedFile)[Object.keys(importedFile)[0]];
    // const moduleRef: NgModuleRef<T> = createNgModuleRef(module, this._injector);
    //const cmpref = this.viewContainerRef.createComponent(componentToOpen, { ngModuleRef: moduleRef });
    const cmpref = this.viewContainerRef.createComponent(componentToOpen);

    return cmpref;
  }

  ngOnDestroy(): void {
    if (this.cmpref)
      this.cmpref.destroy();
    this.viewContainerRef.clear();
  }

}
