import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parceJson'
})
export class StringToJsonPipe implements PipeTransform {
  transform(value: string): any {
    if(!value) value = '{}';
    try {
      return JSON.parse(value.replace('},]', '}]'));
    } catch (error) {
      return {};
    }
   }
  }
