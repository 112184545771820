import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'image-comp',
  templateUrl: './image-comp.component.html',
  styleUrls: ['./image-comp.component.scss']
})
export class ImageContainerComponent implements OnInit {

  @Input() source: string;
  @Input() width: string;
  @Input() height: string;
  @Input() alt: string;
  @Input() className: string;
  @Input() objectFit: string;
  @Input() showLoading: boolean = true;

  @ViewChild('image') image: ElementRef;

  imageLoaded: boolean = false;

  constructor(private host: ElementRef<HTMLElement>) { }

  ngOnInit() {
    const host = this.host.nativeElement;

    if (this.className) {
      host.classList.add(this.className);
    }

    var img = host.firstElementChild.getElementsByTagName('img')[0];

    if (this.width)
      img.style.setProperty('width', this.width);

    if (this.height)
      img.style.setProperty('height', this.height);

    if (this.objectFit)
      (img.style as any).objectFit = this.objectFit;


    host.style.setProperty('--image-comp-height', this.height ?? '100%');
  }

  onImageLoaded() {
    this.imageLoaded = true;
  }
}
