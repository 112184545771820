import { Pipe, PipeTransform } from "@angular/core";

import { DomSanitizer } from "@angular/platform-browser";

@Pipe({ name: "safeHtml" })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(value) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}

@Pipe({ name: "htmlIframe" })
export class SafeHtmlIframePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(value) {
    if (!value) value = '';
    if (value.indexOf('<iframe') <= 0) {
      value = `<iframe width="420" height="315"
      src="${value}">
      </iframe>`;
    }


    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}


@Pipe({ name: "safeUrl" })
export class SafeUrPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(value) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }
}


@Pipe({ name: "htmlToText" })
export class HtmlToTextPipe implements PipeTransform {

  constructor() { }

  transform(html: string): string {
    if (!html) {
      return '';
    }
    // Remove tags HTML usando regex
    let text = html.replace(/<[^>]*>/g, '');

    // Decodificar entidades HTML
    text = this.decodeHtmlEntities(text);

    return text;
  }

  private decodeHtmlEntities(text: string): string {
    const entities: { [key: string]: string } = {
      '&nbsp;': ' ',
      '&amp;': '&',
      '&quot;': '"',
      '&lt;': '<',
      '&gt;': '>',
      // Adicione outras entidades conforme necessário
    };

    return text.replace(/&[^;]+;/g, (entity) => entities[entity] || entity);
  }
}
