import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Gallery, GalleryItem, GalleryRef, ImageItem } from 'ng-gallery';
import { GalleryEntity, GalleryFileEntity } from '../../models/galleryEntity';

import { GalleryService } from '../../services/gallery.service';
import { OrderPipe } from '../../pipes/sort-by.pipe';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss']
})
export class ImageGalleryComponent implements OnInit, OnDestroy {

  subscribe: any;


  @Input() refId: string = uuidv4();
  @Input() galleryId: string;
  @Input() inputedData: boolean;
  @Input() gallery: GalleryEntity;
  @Input() thumb: boolean;
  @Input() disableNavigate: boolean = false;

  @Input() galleryClass: string = 'gallery';

  dots: boolean = false;
  singleMode: boolean;
  photos: GalleryFileEntity[];
  galleryRef: GalleryRef;

  constructor(public galleryService: GalleryService, private galleryServ: Gallery) {

    this.subscribe = this.galleryService.service.onItemLoadEvent$.subscribe(response => {
      this.gallery = response;
      if (response) {
        this.formatData();
      }
    });

  }


  ngOnInit(): void {
    if (this.galleryId) {
      this.galleryService.service.getById(this.galleryId);
    } else if (this.inputedData && this.gallery) {
      this.formatData();
    }

  }

  formatData() {
    if(!this.gallery?.photos) return;

    this.galleryRef = this.galleryServ.ref(this.refId);

    this.photos = JSON.parse(JSON.stringify(this.gallery.photos));
    this.photos = new OrderPipe().transform(this.photos, 'order');

    this.photos.forEach(img => {
      img.srcImage = `${this.galleryService.photoBasePath}/${img.fileName}`;

      this.galleryRef.addImage({
        src: img.srcImage,
        thumb: img.srcImage,
      });

    });

    this.dots = this.photos.length > 1;

  }

  itemClick(index) {
    if(this.disableNavigate)
      return;

    var item = this.photos[index];
    if(item?.urlNavigate) {
      window.open(item?.urlNavigate,'_blank', 'noopener');
    }
  }

  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }


}
