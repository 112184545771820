import { Pipe, PipeTransform } from '@angular/core';

import { GalleryEntity } from '../models/galleryEntity';
import { GalleryHighlightDataEntity } from '../models/highlightDataEntity';
import { GalleryService } from '../services/gallery.service';
import { OrderPipe } from './sort-by.pipe';
import { toogleLeftRight } from '../utils/helpers';

@Pipe({
  name: 'setHighlightPhotoUrl'
})
export class HighlightPhotoUrlPipe implements PipeTransform {

  toogleLeftRight = toogleLeftRight;

  constructor(private galleryService: GalleryService) { }

  transform(highlightData: GalleryHighlightDataEntity[], siteKey: string, configPk?: string): any[] {

    if (!highlightData) return [];

    highlightData = highlightData.filter(i => siteKey ? i.siteKey?.siteKey == siteKey : i.id.toLowerCase() == (configPk || '').toLowerCase());

    highlightData.forEach(element => {
      element.gallery = new GalleryEntity();
      element.gallery.id = element.galleryId;
      element.gallery.photos = element.activeFiles.filter(i => i.active == true).map(i => i.galleryFile);
      element.gallery.photos = new OrderPipe().transform(element.gallery.photos, 'order');

      element.gallery.photos.forEach(img => {
        img.srcImage = `${this.galleryService.photoBasePath}/${img.fileName}`;
        if(img.urlNavigate)
          img.target = img.urlNavigate.indexOf('http') >= 0 ? '_blank' : '_self';
        else
          img.target = 'none';

      });

    });

    this.toogleLeftRight(highlightData);

    return highlightData;

  }
}
