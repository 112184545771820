import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HtmlToTextPipe, SafeHtmlIframePipe, SafeHtmlPipe, SafeUrPipe } from './shared/pipes/safeHtml.pipe';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { RECAPTCHA_LANGUAGE, RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';

import { CommonModule } from '@angular/common';
import { DropdownModule } from "primeng/dropdown";
import { DynamicComponentDirective } from './shared/directives/dynamicDirective';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GalleryModule } from 'ng-gallery';
import { GroupByPipe } from './shared/pipes/group-by.pipe';
import { HighlightPhotoUrlPipe } from './shared/pipes/set-highlight-photoUrl';
import { HttpClientModule } from '@angular/common/http';
import { IConfig } from 'ngx-mask';
import { IFrameResizerDirective } from './shared/directives/appIframeResizer';
import { ImageContainerComponent } from './shared/components/image-comp/image-comp.component';
import { ImageGalleryComponent } from './shared/components/image-gallery/image-gallery.component';
import { NgModule } from '@angular/core';
import { OrderPipe } from './shared/pipes/sort-by.pipe';
import { PeriodFormatPipe } from './shared/pipes/periodFormat.pipe';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { PropertyFilterPipe } from './shared/pipes/filter.pipe';
import { SetLinkUnitPipe } from './shared/pipes/set-link-unit.pipe';
import { SkeletonDirective } from './shared/directives/skeletonDirective';
import { SkeletonLoaderComponent } from './shared/components/skeleton-loader/skeleton-loader.component';
import { StringToJsonPipe } from './shared/pipes/stringToJson.pipe';
import { ToastrModule } from 'ngx-toastr';
import { TooltipModule } from 'primeng/tooltip';
import { YoutubePlayerComponent } from './shared/components/ngx-youtube-embed/ngx-youtube-embed.component';
import { YoutubePlayerService } from './shared/components/ngx-youtube-embed/ngx-youtube-embed.service';
import { environment } from 'src/environments/environment';

let maskOptions: Partial<IConfig> | (() => Partial<IConfig>) = undefined;


@NgModule({
  declarations: [
    ImageGalleryComponent,
    SkeletonLoaderComponent,
    SkeletonDirective,
    ImageContainerComponent,
    IFrameResizerDirective,
    DynamicComponentDirective,
    YoutubePlayerComponent
  ],
  imports: [
    HttpClientModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    RecaptchaModule,
    RecaptchaFormsModule,

    GalleryModule,
    TooltipModule,
    DropdownModule,
    ProgressSpinnerModule,

    NgxMaskDirective,
    NgxMaskPipe,
    ToastrModule.forRoot(),
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,

    GalleryModule,
    SkeletonDirective,
    IFrameResizerDirective,
    ImageContainerComponent,
    DynamicComponentDirective,
    YoutubePlayerComponent,

    //Export Components
    ImageGalleryComponent,
    SkeletonLoaderComponent,
    TooltipModule,
    DropdownModule,
    ProgressSpinnerModule,
    RecaptchaModule,
    RecaptchaFormsModule,

    NgxMaskDirective,
    NgxMaskPipe,

  ],
  providers: [
    YoutubePlayerService
  ]
})
export class SharedModule { }




@NgModule({
  declarations: [
    OrderPipe,
    PropertyFilterPipe,
    GroupByPipe,
    SetLinkUnitPipe,
    HighlightPhotoUrlPipe,
    SafeHtmlPipe,
    SafeHtmlIframePipe,
    SafeUrPipe,
    HtmlToTextPipe,
    StringToJsonPipe,
    PeriodFormatPipe
  ],
  imports: [
    CommonModule,

  ],
  exports: [

    CommonModule,
    //Export Pipes
    OrderPipe,
    PropertyFilterPipe,
    GroupByPipe,
    SetLinkUnitPipe,
    HighlightPhotoUrlPipe,
    SafeHtmlPipe,
    SafeHtmlIframePipe,
    SafeUrPipe,
    HtmlToTextPipe,
    StringToJsonPipe,
    PeriodFormatPipe

  ],
  providers: [

    provideEnvironmentNgxMask(maskOptions),

    {
      provide: RECAPTCHA_SETTINGS, useValue: {
        siteKey: environment.reCAPTCHA,
      } as RecaptchaSettings
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: "pt-br",
    }
  ]
})
export class PipesModule { }
